import Box from '@gtg/components/box'
import Container from '@gtg/components/container'
import Nav from '@gtg/components/nav'
import media from '@gtg/utils/media-queries'
import React from 'react'
import styled from 'styled-components'
import { color, fontSize } from '../styles/theme'
import Footer from '../components/footer'
import GlobalWrapper from '../components/globalWrapper'
import Link from 'gatsby-link'

const BlueBar = styled.div`
  background: ${color.brand500};
  height: 12px;
  width: 100%;
  position: relative;
  z-index: 1;
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  h1 {
    font-size: ${fontSize.f12};
    color: ${color.brand300};
    ${media.lg`
      font-size: ${fontSize.f10};
    `}
  }
`

const Center = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const ContentSection = styled.section`
  flex: 1;
`

const StyledBox = styled(Box)`
  background: ${color.coal50};
`

const StyledNav = styled(Nav)`
  height: 104px;
`

const NotFoundPage = () => {
  return (
    <GlobalWrapper>
      <Flex>
        <BlueBar />
        <StyledBox py={0}>
          <Container wMax="xl">
            <StyledNav
              withLayout={false}
              logoStyle="dark"
              textColor={color.coal900}
            />
          </Container>
        </StyledBox>
        <ContentSection>
          <Container>
            <Center>
              <Box p={5}>
                <title>Not found</title>
                <h1>Ups...</h1>
                <p>
                  <em>Diese Seite scheint nicht zu existieren.</em>
                  <br />
                  Schreibe uns gerne eine{' '}
                  <a href="mailto:info@lenzbox.com">E-Mail</a>, wenn du etwas
                  Bestimmtes suchst.
                </p>
                <br />
                <br />
                <Link to="/">
                  <button>Zur Startseite</button>
                </Link>
              </Box>
            </Center>
          </Container>
        </ContentSection>
        <Footer wMax="xl" />
      </Flex>
    </GlobalWrapper>
  )
}

export default NotFoundPage
